<template>
  <CCard>
    <div
      v-if="isLoading"
      :style="{ height: '80vh' }"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <CSpinner
        class="custom-spinner"
        color="info"
        grow
      />
    </div>
  
    <div v-else>
      <CCardHeader>
        <slot name="header">        
          <CIcon
            name="cil-basket"
            class="mr-1"
          /> Creatives
        </slot>
      </CCardHeader>
  
      <CCardBody>
        <div class="d-flex align-items-center mb-3">
          <CSwitch
            variant="3d"
            color="primary"
            horizontal
            size="sm"
            class="mr-2"           
            :checked.sync="showRejected"
            @update:checked="loadCreatives"
          />
          <h5 class="m-0">
            Show Rejected 
          </h5>          
        </div>
        <CDataTable
          small
          border
          sorter
          hover
          column-filter
          :items="reviewedCreatives"
          :fields="fields"
          :items-per-page="10"
          :pagination="{align: 'center'}"
        >
          <template #id="{item}">
            <td>
              {{ item.id }}
              <a
                href
                class="ml-1"
                @click.prevent="
                  copyIdToClipboard(getSafe(() => item.id))
                "
              ><CIcon
                name="cil-copy"
              /></a> 
            </td>
          </template>

          <template #account="{item}">
            <td>
              <router-link
                :to="{ name: 'SellerAccountDetails', params: { id: getSafe(() => item.account.id)} }"
                target="_blank"                 
              >
                {{ getSafe(() => item.account.name) }}
              </router-link>

              <a
                href
                class="ml-1"
                @click.prevent="
                  copyAccountIdToClipboard(getSafe(() => item.account.id))
                "
              ><CIcon
                name="cil-copy"
              /></a>            
            </td>
          </template>

          <template #name="{item}">
            <td>
              {{ item.name }}
            </td>
          </template>

          <template #category="{item}">
            <td>
              {{ getSafe(() => item.category.name) }}
            </td>
          </template>

          <template #trafficSource="{item}">
            <td>
              {{ getSafe(() => item.trafficSource.name) }}
            </td>
          </template>

          <template #country="{item}">
            <td>
              {{ getSafe(() => item.country.id) }}
            </td>
          </template>

          <template #language="{item}">
            <td>
              {{ getSafe(() => item.language.id) }}
            </td>
          </template>

          <template #stats="{item}">
            <td>
              <div
                v-if="item.stats"
                class="w-fixed-130" 
              >
                <div>Total: {{ getSafe(() => item.stats.total) }}</div>
                <div>Pending: {{ getSafe(() => item.stats.pending) }}</div>
                <div>Approved: {{ getSafe(() => item.stats.approved) }}</div>
                <div>Rejected: {{ getSafe(() => item.stats.rejected) }}</div>
              </div>
            </td>
          </template>

          <template #Actions="{item}">
            <td>
              <CButton
                color="info"
                size="sm"
                :to="`/dashboard/creatives/${item.id}`"
              >
                View Creative
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </div>
  </CCard>
</template>
  
  <script>

  import { mapActions, mapState } from 'vuex'  
  import { showSuccessMessage } from '@/notification-utils'
  import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin' 
  
  export default {
    name: 'Creatives',
  
    title: 'Creatives',  

    mixins: [copyToClipboardMixin],
    
    data() {
      return {
        type: '',
        isLoading: false,       
        showRejected: false,
      }
    },
  
    computed: {
      ...mapState('creative', ['reviewedCreatives']),
  
      fields () {
        return [
          { key: 'id', label: 'ID', sortable: true },
          { key: 'account', label: 'Account', sortable: true },
          { key: 'externalId', label: 'External ID', sortable: true },
          { key: 'name', label: 'Name', sortable: true },               
          { key: 'category', label: 'Category', sortable: true },               
          { key: 'trafficSource', label: 'Traffic Source', sortable: true },               
          { key: 'country', label: 'Country', sortable: true },               
          { key: 'language', label: 'Language', sortable: true },               
          { key: 'status', label: 'Status', sortable: true },               
          { key: 'stats', label: 'Buyer Approval Statistics', filter: false, sorter: false },               
          { key: 'Actions', filter: false }
        ]
      }, 
      
    },
  
    async created() {
      await this.loadCreatives()
    }, 

    methods: {
      ...mapActions('creative', ['getReviewedCreatives']),

      async loadCreatives() {
        try {
          this.isLoading = true
          await this.getReviewedCreatives(this.showRejected)
        } finally {
          this.isLoading = false
        }
      },

      copyIdToClipboard(id) {
        this.copyTextToClipboard(id)
        showSuccessMessage('Creative ID copied to clipboard')
      },

      copyAccountIdToClipboard(id) {
        this.copyTextToClipboard(id)
        showSuccessMessage('Account ID copied to clipboard')
      }  
    }
  }
  </script>
  
  <style></style>
  